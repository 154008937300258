import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FilledInput,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { INPUT_DEBOUNCE_DURATION } from 'common/constant';
import { useDebounceCallBack } from 'hooks/useDebounceCallback';
import ArrowDropdown from 'icons/ArrowDropdown';
import BadgeDot from 'icons/BadgeDot';
import CloseIcon from 'icons/CloseIcon';
import SearchIcon from 'icons/SearchIcon';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { resetBlockCategoriesAction } from 'store/actions/blockCategoriesAction';
import { getCollectionsAction } from 'store/actions/collectionActions';
import {
  getCategories,
  getCollections,
  getFilterState,
  getPaginationCategories,
  getPaginationCollections,
  modeTheme,
} from 'store/selectors';
import { CollectionData } from 'types/collections';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { updateFilterAction } from 'store/actions/filterActions';
import { TRANSPARENCY } from 'material/colors';
import { ChainLabel } from 'enums/filter';
// import { CircularProgress } from '@material-ui/core';

interface IData {
  isExpand: boolean;
  dropdownComponents: any[];
  type: 'price' | 'collectionIds' | 'chains' | 'categories';
  StartAdornment?: any;
  setIsExpand?: (value: boolean) => void;
  isDeadzone?: boolean;
  dropdown: string;
  setDropdown: (value: string) => void;
}

var collectionCache: CollectionData[] = [];

function FilterDetail({
  dropdownComponents,
  StartAdornment,
  isExpand,
  type,
  isDeadzone,
  setIsExpand,
  dropdown,
  setDropdown,
}: IData) {
  const [collectionFilter, setCollectionFilter] = useState<string>('');
  const filter = useSelector(getFilterState);
  const collections = useSelector(getCollections);
  const categories = useSelector(getCategories);
  const dispatch = useDispatch();
  const collectionPagination = useSelector(getPaginationCollections);
  const categoriesPagination = useSelector(getPaginationCategories);
  const hasMore =
    collectionPagination.pageNumber * collectionPagination.pageSize <
    collectionPagination.total;
  const hasMoreCategories =
    categoriesPagination.pageNumber * categoriesPagination.pageSize <
    categoriesPagination.total;
  const theme = useTheme();
  const history = useHistory();
  const mode = useSelector(modeTheme);

  const isCategoryType = useMemo(() => {
    return type === 'categories';
  }, [type]);

  const isCategoriesHasItem =
    isCategoryType &&
    filter.categories.length > 0 &&
    !filter.categories.includes('NFT');

  const classes = useStyles({
    isExpand,
    isDropdown: dropdown === type,
    isDeadzone,
    isCategoriesHasItem,
    type,
    isCategoryType,
  });

  const onRemove = (value: any) => {
    if (type === 'categories') {
      dispatch(
        updateFilterAction({
          categories: ['NFT'],
        }),
      );
      dispatch(resetBlockCategoriesAction());
      history.push('/nft');
    } else {
      const filterChanged = [...filter[type]];
      const index = filterChanged.indexOf(value);
      if (index < 0) return;
      filterChanged.splice(index, 1);
      dispatch(
        updateFilterAction({
          [type]: filterChanged,
          blockNumber: undefined,
          blockCategory: undefined,
        }),
      );
    }
  };

  const getCollectionName = useCallback(
    (id: number) => {
      const collectionCurrent =
        collections.length < collectionCache.length
          ? collectionCache
          : collections;
      const collection = collectionCurrent.find((c) => c.id === id);
      if (!collection) return;
      return collection.name;
    },
    [collections],
  );

  const getRenderLabel = useCallback(
    (type: string, item: any) => {
      if (type === 'collectionIds') {
        return getCollectionName(item);
      }

      if (type === 'categories') {
        return `/${item}`;
      }

      if (type === 'chains') {
        return ChainLabel[item as keyof typeof ChainLabel];
      }

      return item;
    },
    [getCollectionName],
  );

  const renderTextByFilterType = useMemo(() => {
    switch (type) {
      case 'price':
        return 'Sort squares';
      case 'collectionIds':
        return 'All collections';
      case 'categories':
        return 'All galleries';
      case 'chains':
        return 'All chains';
    }
  }, [type]);

  const debouncedOnGetCollectionByName = useDebounceCallBack(
    (v: string) => {
      dispatch(
        getCollectionsAction({
          pageNumber: 1,
          pageSize: 5,
          name: v,
        }),
      );
    },
    INPUT_DEBOUNCE_DURATION,
    [dispatch, collectionFilter],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    debounce(debouncedOnGetCollectionByName, INPUT_DEBOUNCE_DURATION),
    [],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionFilter(event.target.value);
    debounceFn(event.target.value);
  };

  const loadMoreCollections = () => {};

  const openFilter = useCallback(() => {
    if (!isDeadzone && !isExpand && setIsExpand) {
      setIsExpand(!isExpand);
      setDropdown(type);
    }
  }, [isExpand, type, isDeadzone, setIsExpand, setDropdown]);

  const getColorStartAdornment = useCallback(() => {
    if (isCategoriesHasItem && !isExpand && mode === 'light') {
      return theme.colors.primaryLight;
    }
    if (isCategoriesHasItem && !isExpand && mode === 'dark') {
      return theme.colors.white;
    }
    return mode === 'dark'
      ? theme.colors.secondaryDark
      : theme.colors.secondaryLight;
  }, [isCategoriesHasItem, mode, theme.colors, isExpand]);

  useEffect(() => {
    if (collectionCache.length < collections.length) {
      collectionCache = collections;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections.length, collectionCache.length]);

  // const loader = useMemo(() => {
  //   return (
  //     <div className={classes.loader}>
  //       <CircularProgress color="secondary" size={16} />
  //     </div>
  //   );
  // }, [classes.loader]);

  const renderColorDivider = useMemo(() => {
    return mode === 'dark'
      ? `${theme.colors.secondaryDark}${TRANSPARENCY.T32}`
      : `${theme.colors.secondaryLight}${TRANSPARENCY.T30}`;
  }, [mode, theme.colors]);

  const hasAtLeastActiveFilter = useMemo(() => {
    if (type === 'categories') {
      return !filter.categories.includes('NFT');
    }
    return filter[type].length > 0;
  }, [filter, type]);

  const renderActiveFilter = useMemo(() => {
    if (type === 'categories') {
      if (filter.categories.includes('NFT')) return [];
    }
    return filter[type];
  }, [filter, type]);

  return (
    <Box className={classes.main} onClick={() => openFilter()}>
      <Box
        className={clsx(classes.header, {
          [classes.categoryHeader]: isCategoryType,
        })}
      >
        <StartAdornment color={getColorStartAdornment()} />
        {!isExpand && hasAtLeastActiveFilter && (
          <BadgeDot
            color={
              mode === 'dark' ? theme.colors.crimson : theme.colors.primaryLight
            }
          />
        )}
        {isExpand && (
          <Box className={classes.listSelectItems}>
            {renderActiveFilter?.length > 0 ? (
              renderActiveFilter.map((item, index) => (
                <Box
                  key={index}
                  className={clsx(classes.selectData, {
                    [classes.chainText]: type === 'chains',
                  })}
                >
                  <Typography>{getRenderLabel(type, item)}</Typography>
                  <IconButton onClick={() => !isDeadzone && onRemove(item)}>
                    <CloseIcon
                      color={theme.colors.white}
                      width={16}
                      height={16}
                    />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Typography
                className={clsx(classes.selectAll, {
                  [classes.selectAllCategories]: isCategoryType,
                })}
              >
                {renderTextByFilterType}
              </Typography>
            )}
          </Box>
        )}
        {isExpand && (
          <Box display="flex" justifyContent="center">
            <Divider
              orientation="vertical"
              flexItem
              style={{
                height: 24,
                marginTop: 18,
                backgroundColor: renderColorDivider,
              }}
            />
            <Button
              className={classes.btnDrop}
              onClick={() =>
                !isDeadzone && setDropdown(dropdown === type ? '' : type)
              }
            >
              <ArrowDropdown />
            </Button>
          </Box>
        )}
      </Box>
      {type === 'collectionIds' && isExpand && dropdown === type && (
        <FilledInput
          value={collectionFilter}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          aria-describedby="filled-weight-helper-text"
          className={classes.search}
          placeholder="Search collection"
          disableUnderline
        />
      )}
      {dropdown === type &&
        isExpand &&
        (type !== 'collectionIds' && type !== 'categories' ? (
          <Box>{dropdownComponents.map((d) => d)}</Box>
        ) : (
          <InfiniteScroll
            dataLength={
              type === 'collectionIds' ? collections.length : categories.length
            }
            next={loadMoreCollections}
            hasMore={type === 'collectionIds' ? hasMore : hasMoreCategories}
            className={classes.wrapperDetail}
            loader={null}
            height="fit-content"
          >
            {dropdownComponents.map((d) => d)}
          </InfiniteScroll>
        ))}
    </Box>
  );
}

export default FilterDetail;
